import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { StockContext } from "context/stock-context"

import Layout from "components/layout"
import { HomeNews } from "components/pages/home/home-news"
import { HomeCover } from "components/pages/home/home-cover"
import { HomeOverview } from "components/pages/home/home-overview"
import { HomeHighlights } from "components/pages/home/home-highlights"
import { StockPerformance } from "components/global/stock-performance/stock-performance"
import SEO from "components/seo"
import { HomeModal } from "./home-modal"
import { HomeBillboard } from "./home-billboard"
import Ads from "components/ads";
import DFPAds from "components/ads";

//Home
const Index = ({ pageContext, path, location }) => {
  const data = useStaticQuery(GET_HOME_PAGE)
  const lang = pageContext.langKey
  const { stock } = useContext(StockContext)

  const pageData =
    lang === "en"
      ? data?.wordPress?.page?.homePage
      : data?.wordPress?.page?.translation?.homePage ||
        data?.wordPress?.page?.homePage

  const newsList = data.wordPress.posts.nodes
  const highlights = data?.wordPress?.page?.homePage?.highlights

  const bgColor =
    lang === "en"
      ? data?.wordPress?.page?.backgroundColor?.backgroundColor?.color
      : data?.wordPress?.page?.translation?.backgroundColor?.backgroundColor
          ?.color ||
        data?.wordPress?.page?.backgroundColor?.backgroundColor?.color

  const seo =
    lang === "en"
      ? data.wordPress.page.seo
      : data.wordPress.page.translation.seo || data.wordPress.page.seo

  const SEODefaultImg =
    pageData.cover.imageDekstop.sourceUrl ||
    pageData.cover.imageDekstop.sourceUrl

  const ads = data?.wordPress?.ads?.ads

  return (
    <Layout theme={bgColor} lang={pageContext.langKey} path={path}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
      />
        {/*<DFPAds/>*/}

      <HomeCover theme={bgColor} data={pageData.cover} />
      <HomeOverview data={pageData.overview} />
      <HomeHighlights data={highlights} lang={lang} location={location} />
      {/* <InvestorStockPerformance stock={stock} /> */}
      <HomeBillboard data={ads?.billboardHome} />
      <StockPerformance
        className="bg-light"
        data={stock}
        lang={lang}
        location={location}
      />
      <HomeNews data={newsList} lang={lang} />
      {ads?.popUpHome && <HomeModal data={ads?.popUpHome} />}
    </Layout>
  )
}

export default Index

const GET_HOME_PAGE = graphql`
  query Home {
    wordPress {
      page(id: "home", idType: URI) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          twitterImage {
            sourceUrl
            altText
          }
          opengraphTitle
          opengraphDescription
          opengraphImage {
            sourceUrl
            altText
          }
        }
        homePage {
          overview {
            title
            text
            buttons {
              url
              text
              icon {
                sourceUrl
                altText
              }
            }
          }
          highlights {
            featured {
              ... on WordPress_Project {
                title
                projectDetailsPage {
                  cover {
                    title
                    image {
                      sourceUrl
                      altText
                    }
                  }
                  headline {
                    text
                  }
                }
                slug
                projectCategories {
                  nodes {
                    slug
                    name
                  }
                }
                projectLocations {
                  nodes {
                    slug
                    name
                  }
                }
                translation(language: ID) {
                  title
                  projectDetailsPage {
                    cover {
                      title
                      image {
                        sourceUrl
                        altText
                      }
                    }
                    headline {
                      text
                    }
                  }
                  slug
                  projectCategories {
                    nodes {
                      slug
                      name
                    }
                  }
                  projectLocations {
                    nodes {
                      slug
                      name
                    }
                  }
                }
              }
            }
          }
          cover {
            title
            text
            imageMobile {
              sourceUrl
              altText
            }
            imageDekstop {
              sourceUrl
              altText
            }
            buttons {
              url
              text
              icon {
                sourceUrl
                altText
              }
              iconHover {
                sourceUrl
                altText
              }
            }
          }
        }
        translation(language: ID) {
          backgroundColor {
            backgroundColor {
              color
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            twitterImage {
              sourceUrl
              altText
            }
            opengraphTitle
            opengraphDescription
            opengraphImage {
              sourceUrl
              altText
            }
          }
          homePage {
            overview {
              title
              text
              buttons {
                url
                text
                icon {
                  sourceUrl
                  altText
                }
              }
            }
            cover {
              title
              text
              imageMobile {
                sourceUrl
                altText
              }
              imageDekstop {
                sourceUrl
                altText
              }
              buttons {
                url
                text
                icon {
                  sourceUrl
                  altText
                }
                iconHover {
                  sourceUrl
                  altText
                }
              }
            }
          }
        }
      }
      ads: page(id: "ads", idType: URI) {
        ads {
          popUpHome {
            url
            text
            scheduler {
              startDate
              endDate
            }
            image {
              sourceUrl
            }
          }
          billboardHome {
            url
            scheduler {
              startDate
              endDate
            }
            image {
              sourceUrl
            }
          }
        }
      }
      posts(where: { language: EN }, first: 8) {
        nodes {
          slug
          title
          categories {
            nodes {
              name
              slug
            }
          }
          date
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          translation(language: ID) {
            title
          }
        }
      }
    }
  }
`
