import React from "react"
import { useEffect } from "react"
import { useState } from "react"

import { Modal } from "../../anti/modal/modal"
import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"
import { adsScheduler } from "../../../utils/ads-scheduler"
import {setupDfpSlot} from "utils/setup-dfp-slot";


export const HomeModal = ({ data }) => {
  const [modal, setModal] = useState(null)
  const showAds = adsScheduler(data?.scheduler)

  useEffect(() => {
    setTimeout(() => {
      if (showAds) {
        setModal("pop-up")
        // setupDfpSlot({
        //   path: '/21835028929/modal-duti-staging',
        //   size: [[850,559], [425,280], [303,799]],
        //   id: 'div-gpt-ad-1690777648366-0',
        //   mapping: [
        //         [[1050,768],[850,559]],
        //         [[425,240],[425,280]],
        //         [[320,400],[300,250]]
        //   ]
        // });
      }
    }, 6000)
  }, [])



  return (
    <Modal
      id="pop-up"
      isShowing={modal}
      hide={() => setModal(null)}
      className="modal modal-lg modal-pop-up justify-content-center"
    >
      <div className="row">
        <div className={`col-md-${data?.text ? "8" : "12"}`}>
          <Card
              imgRatio="r-1-1"
              img={data?.image?.sourceUrl}
              imgAlt="Ads"
              className="img-fluid"
          />
          {/* <img src={data?.image?.sourceUrl} className="img-fluid" alt="Ads" /> */}
        </div>
        {data?.text && (
            <div className="col-md-4 order-md-first">
              <div className="p-4 body-wrapper">
                <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                <Button variant="primary" className="btn-block" link={data?.url}>
                  Learn More
                </Button>
              </div>
            </div>
        )}
      </div>
    </Modal>
  )
}
